var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"room-card",class:{
      'room-card-selected': this.markers && _vm.isSelected,
      'room-card-disabled': _vm.isDisabled,
      pointer: !_vm.isDisabled,
    }},[_c('div',{staticClass:"room-card-header"},[_c('div',{staticClass:"room-card-header-text"},[_vm._v(_vm._s(_vm.room.name))])]),_c('div',{staticClass:"room-card-content-container room-card-content"},[_c('div',{staticClass:"room-card-details"},[_c('div',{staticClass:"room-card-content-text"},[_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-hotel")]),_c('div',{staticClass:"room-card-content-details"},[_vm._v(" "+_vm._s(_vm.room.kingBeds > 0 ? `${_vm.room.kingBeds} King bed` : null)+" "+_vm._s(_vm.room.kingBeds > 0 && (_vm.room.queenBeds > 0 || _vm.room.singleBeds > 0 || _vm.room.doubleBeds > 0) ? `, ` : null)+" "+_vm._s(_vm.room.queenBeds > 0 ? `${_vm.room.queenBeds} Queen beds` : null)+" "+_vm._s(_vm.room.queenBeds > 0 && (_vm.room.singleBeds > 0 || _vm.room.doubleBeds > 0) ? `, ` : null)+" "+_vm._s(_vm.room.singleBeds > 0 ? `${_vm.room.singleBeds} Single beds` : null)+" "+_vm._s(_vm.room.queenBeds > 0 && _vm.room.doubleBeds > 0 ? `, ` : null)+" "+_vm._s(_vm.room.doubleBeds > 0 ? `${_vm.room.doubleBeds} Double beds` : null)+" ")])],1),_c('div',{staticClass:"room-card-content-text"},[_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-key")]),_c('div',{staticClass:"room-card-content-details"},[_vm._v("Access hotel amenities")])],1)]),_c('div',{staticClass:"room-card-content hours-card color-card"},[_c('div',{staticClass:"room-card-rates",style:([
            !_vm.room.isAvailableThree
              ? { background: '#F5F5F5' }
              : this.isSelected && this.markers === '3h'
              ? { border: '2px solid #FF4500' }
              : { border: '2px solid #ddd' },
          ]),on:{"click":function($event){_vm.room.isAvailableThree && _vm.sendTimeData('3h')}}},[_c('div',{staticClass:"card-style"},[_c('div',{staticClass:"time-checkbox",class:{
                'time-checkbox-selected': this.isSelected ? this.markers === '3h' : false,
              },on:{"click":function($event){_vm.room.isAvailableThree && _vm.sendTimeData('3h')}}}),_c('div',{staticClass:"room-card-hour-value",style:([
                _vm.room.isAvailableThree
                  ? this.isSelected && this.markers === '3h'
                    ? { 'font-weight': '700', color: '#FF4500' }
                    : { 'font-weight': '700', color: '#080914' }
                  : { 'font-weight': '500' },
              ])},[_vm._v(" "+_vm._s(_vm.room.isAvailableThree ? `${_vm.currency?.shortName}${Math.ceil(_vm.room.threeHourRate * _vm.currency.exchangeRate)}` : '--')+" ")])]),_c('div',{staticClass:"room-card-hour-header",style:([
              _vm.room.isAvailableThree
                ? this.isSelected && this.markers === '3h'
                  ? { 'font-weight': '700', color: '#FF4500' }
                  : { 'font-weight': '700', color: '#080914' }
                : { 'font-weight': '500' },
            ])},[_vm._v(" 3 hours ")])]),_c('div',{staticClass:"room-card-rates",style:([
            !_vm.room.isAvailableSix
              ? { background: '#F5F5F5' }
              : this.isSelected && this.markers === '6h'
              ? { border: '2px solid #FF4500' }
              : { border: '2px solid #ddd' },
          ]),on:{"click":function($event){_vm.room.isAvailableSix && _vm.sendTimeData('6h')}}},[_c('div',{staticClass:"card-style"},[_c('div',{staticClass:"time-checkbox",class:{
                'time-checkbox-selected': this.isSelected ? this.markers === '6h' : false,
              },on:{"click":function($event){_vm.room.isAvailableSix && _vm.sendTimeData('6h')}}}),_c('div',{staticClass:"room-card-hour-value",style:([
                _vm.room.isAvailableSix
                  ? this.isSelected && this.markers === '6h'
                    ? { 'font-weight': '700', color: '#FF4500' }
                    : { 'font-weight': '700', color: ' #080914' }
                  : { 'font-weight': '500' },
              ])},[_vm._v(" "+_vm._s(_vm.room.isAvailableSix ? `${_vm.currency?.shortName}${Math.ceil(_vm.room.sixHourRate * _vm.currency.exchangeRate)}` : '--')+" ")])]),_c('div',{staticClass:"room-card-hour-header",style:([
              _vm.room.isAvailableSix
                ? this.isSelected && this.markers === '6h'
                  ? { 'font-weight': '700', color: '#FF4500' }
                  : { 'font-weight': '700', color: '#080914' }
                : { 'font-weight': '500' },
            ])},[_vm._v(" 6 hours ")])]),_c('div',{staticClass:"room-card-rates",style:([
            !_vm.room.isAvailableTwelve
              ? { background: '#F5F5F5' }
              : this.isSelected && this.markers === '12h'
              ? { border: '2px solid #FF4500' }
              : { border: '2px solid #ddd' },
          ]),on:{"click":function($event){_vm.room.isAvailableTwelve && _vm.sendTimeData('12h')}}},[_c('div',{staticClass:"card-style"},[_c('div',{staticClass:"time-checkbox",class:{
                'time-checkbox-selected': this.isSelected ? this.markers === '12h' : false,
              },on:{"click":function($event){_vm.room.isAvailableTwelve && _vm.sendTimeData('12h')}}}),_c('div',{staticClass:"room-card-hour-value",style:([
                _vm.room.isAvailableTwelve
                  ? this.isSelected && this.markers === '12h'
                    ? { 'font-weight': '700', color: '#FF4500' }
                    : { 'font-weight': '700', color: '#080914' }
                  : { 'font-weight': '500' },
              ])},[_vm._v(" "+_vm._s(_vm.room.isAvailableTwelve ? `${_vm.currency?.shortName}${Math.ceil(_vm.room.twelveHourRate * _vm.currency.exchangeRate)}` : '--')+" ")])]),_c('div',{staticClass:"room-card-hour-header",style:([
              _vm.room.isAvailableTwelve
                ? this.isSelected && this.markers === '12h'
                  ? { 'font-weight': '700', color: '#FF4500' }
                  : { 'font-weight': '700', color: '#080914' }
                : { 'font-weight': '500' },
            ])},[_vm._v(" 12 hours ")])])])]),(_vm.showFreeCancelDateTime)?_c('v-flex',{staticClass:"room-free-cancellation"},[_c('v-icon',{staticClass:"free-cancel-icon"},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Free cancellation until 24 hours before checkin. ")],1):_vm._e(),(_vm.isDisabled)?_c('div',{staticClass:"error-text"},[_c('v-icon',{attrs:{"color":"black","size":"18"}},[_vm._v("mdi-alert-circle")]),_c('span',[_vm._v("This room is unavailable")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }