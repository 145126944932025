<template>
  <div class="account-container" v-bind:style= "[isMobile ? { marginTop: '50px'} : { marginTop: '0px'}]">
    <div>
      <h1>Bookings</h1>
      <v-card class="side-navbar" max-width="270" min-width="270">
        <div
          @click="itemClick('upcoming')"
          :class="{'selected': selectedItem === 'upcoming'}"
          class="card-section"
        >
          <b>Upcoming bookings</b>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div
          @click="itemClick('past')"
          :class="{'selected': selectedItem === 'past'}"
          class="card-section"
        >
          <b>Past bookings</b>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
      </v-card>
    </div>
    <div class="width-100">
      <div v-if="selectedItem === 'upcoming'">
        <UpcomingBookings />
      </div>
      <div v-if="selectedItem === 'past'">
        <PastBookings />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import UpcomingBookings from '@/components/Bookings/UpcomingBookings.vue';
import PastBookings from '@/components/Bookings/PastBookings.vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

const { getCurrencies } = require('@/services/user/user.service');

const { getLoggedInUserDetailService } = require('@/services/user/user.service');

export default {
  name: 'Bookings',
  data() {
    return {
      selectedItem: 'upcoming',
      isMobile: window.innerWidth <= 768,
    };
  },
  components: {
    UpcomingBookings,
    PastBookings,
  },
  mounted() {
    this.setHeaderAccess();
  },
  created() {
    this.selectedItem = this.$route.params.mode || 'upcoming';
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.mode': function () {
      this.selectedItem = this.$route.params.mode;
    },
  },
  methods: {
    ...mapActions(['setCurrencyData']),
    itemClick(value) {
      this.$router.push({
        path: `../bookings/${value.toLowerCase()}`,
      });
    },
    async setHeaderAccess() {
      const isLoggedIn = this.$cookies.get('isLoggedIn');
      const token = this.$cookies.get('token');
      if ((isLoggedIn === 'false' && !token) || isLoggedIn === null) {
        const getVatgstDetails = await getCurrencies();
        if (!Vue.$cookies.get('localeInfo') || Vue.$cookies.get('localeInfo').countryNameVatgst === '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
          this.setCurrencyData(filterCountry[0]);
        }
        if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
          if (!filterCountry.length) {
            const aUfilterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
            this.setCurrencyData(aUfilterCountry[0]);
          } else {
            this.setCurrencyData(filterCountry[0]);
          }
        }
      } else {
        const userDetails = await getLoggedInUserDetailService();
        this.setCurrencyData(userDetails.data.vatgstDetails[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-container {
  padding: 2.9rem 0 2.9rem 0;
  display: flex;
  .card-section {
    padding: 12px 16px;
    border-bottom: 1px solid #dcdde6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .side-navbar {
    margin: 26px 40px 0 0;
    .selected {
      color: $primary-color;
      .v-icon {
        color: $primary-color;
      }
    }
  }
  .width-100 {
    width: 100%;
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .account-container {
    flex-direction: column;
  }
  .side-navbar {
    margin-bottom: 20px !important;
    min-width: 100% !important;
    max-width: 100% !important;
    flex-direction: row !important;
    display: flex;
    justify-content: center;
    .card-section {
      border-bottom: unset !important;
    }
  }
}
</style>
