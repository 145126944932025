<template>
  <div style="margin-top: 5%;">
    <a @click="$router.go(-1)" class="page-back" v-if="isMobile" v-bind:style= "[isMobile ? {'margin-top': '25%'} : {'margin-top': '0px'}]">
      <v-icon class='page-back-icon'>mdi-arrow-left</v-icon>
      <div class="page-back-text">Back to search results</div></a>
    <v-row class="loader" v-if="loadingInProgress" align-content="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <div v-if="!loadingInProgress" class="view-hotel-container" >
      <div class="view-hotel-heading-outer-container">
        <div class="view-hotel-heading-container">
          <div class="view-hotel-heading">
            <h1>{{selectedHotel.name}}</h1>
            <div v-if="showFavorite">
            <v-icon v-if="selectedHotel?.isFavorited"
              @click="toogleFavourites(selectedHotel?.isFavorited)" class="favourite-icon favourite">
              mdi-heart
            </v-icon>
            <v-icon v-else
              @click="toogleFavourites(selectedHotel?.isFavorited)" class="favourite-icon favourite">
              mdi-heart-outline
            </v-icon>
            </div>
          </div>
          <div>
            <v-icon class="location-icon">mdi-map-marker</v-icon>
            <span>{{selectedHotel.streetAddress}}, {{selectedHotel.city}},
                {{selectedHotel.state}},  {{selectedHotel.country}},
                {{selectedHotel.postalCode}}</span>
          </div>
        </div>
        <div class="row" v-if="rating > 0">
          <v-flex c-flex class="reviews-top">
            <h3>{{allReviews.length}} {{allReviews.length > 1 ? 'Reviews' : 'Review'}}</h3>
            <Button
              type="link"
              color="primary"
              value="Read Reviews"
              buttonClass="continue-button"
              @buttonClicked="goto('customerReviewsRef')"
            />
          </v-flex>
          <div class="category-rating">{{rating}} <br/> {{ratingInfo}}</div>
        </div>
      </div>
      <v-flex d-flex class="left-right-outer-container">
        <div class="left-container">
          <div>
            <div class="no-image-card" v-if="selectedHotel.photos
            && selectedHotel.photos.length === 0">
              <div>
                <v-img src="@/assets/no-image.svg"></v-img>
              </div>
            </div>
            <div v-if="selectedHotel.photos && selectedHotel.photos.length > 0">
              <Carousel :photos="selectedHotel.photos"
              :showArrows="selectedHotel.photos.length > 1 ? true : false" />
            </div>
          </div>
          <h2 style="margin-top:10px;  margin-bottom:10px; margin-left: 10px">About this hotel</h2>
          <v-card class="margin-bottom-26 box-sixe">
            <v-card-title class="card-title">Location</v-card-title>
            <div class="map-container">
              <GoogleMap :markers="lonlatMarkers" />
            </div>
          </v-card>
            <v-card class="padding-bottom-26 margin-bottom-26 box-sixe ">
            <v-card-title class="card-title">Description</v-card-title>
            <div class="amenities-container">
              <div v-if="selectedHotel.description !== ''" class="amenities-list">
                <div v-html="renderDescriptionHTML(selectedHotel.description)" />
              </div>
            </div>
            <div v-if="selectedHotel.description !== ''"
              @click="toggleReadMore" class="outline-btn">{{this.wordsToShow === 50 ? "Read more" : "Read less"}}
            </div>
            <div v-if="selectedHotel.description === ''"
              class="no-search-results">
                No description available for this hotel.
            </div>
          </v-card>
          <v-card class="padding-bottom-26 margin-bottom-26 box-sixe ">
            <v-card-title class="card-title">Included amenities</v-card-title>
            <div v-if="selectedHotel.amenities.length > 0" class="amenities-container">
              <div v-for="(amenitie, i) in amenities" :key="i" class="amenities-list">
                <div class="amenitie-icon">
                  <v-img height="18px" width="18px" :src="getIconAmenities(amenitie)"></v-img>
                </div>
                {{amenitie}}
              </div>
            </div>
            <div v-if="selectedHotel.amenities && selectedHotel.amenities.length > 4"
              @click="loadAmenitie()" class="outline-btn">
              {{(selectedHotel.amenities.length > amenities.length)
              ? `Show all amenities (${selectedHotel.amenities.length})` : 'Show less'}}
            </div>
            <div v-if="amenities.length === 0"
              class="no-search-results">
                No amenities
            </div>
          </v-card>
          <!-- <div ref="customerReviewsRef" v-if="reviews.length > 0">
            <v-card class="margin-bottom-26">
              <v-card-title class="card-title">Customer Reviews</v-card-title>
              <div class="ratings-box">
                <div class="row" style="padding-top:20px;">
                    <div v-for="(category, index) in categories" :key="index"
                    class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style=" padding:0px; margin:0px;">
                      <div class="rating-flex">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                          style="margin-top: -10px;">
                            <strong class="radio-category">{{category}}</strong>
                          </div>
                          <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <v-progress-linear
                              :value="((getReviewElement(category)[category]/5)*100)"
                              color="#FF4500"
                            />
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12"
                          style="margin-top: -10px;">
                            <strong>
                              {{ (getReviewElement(category)[category]) }}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="review-box">
                    <div class="reviews" v-for="(eachReview, index) in reviews" :key="index">
                      <div style="display: flex; align-items: center;">
                        <div class="user-tile">
                          <h3>{{eachReview.name[0]}}</h3>
                        </div>
                        <div class="user-name">
                          <h4>{{eachReview.name}}</h4>
                          <p class="review-date">{{eachReview.date}}</p>
                        </div>
                      </div>
                      <div class="review-details">
                        <div v-html="renderHTML(eachReview.review)" />
                        <div class="review-response" v-if="eachReview.array.reply">
                          <div class="user-name">
                            <div class="row">
                              <img
                                :src="selectedHotel.photos[0]"
                                class="round-image"
                              />
                              <div class="hotel-details">
                                <h4>{{selectedHotel.name}}</h4>
                                <p class="review-date">{{eachReview.array.updatedAt}}</p>
                              </div>
                            </div>
                            <div v-html="renderHTML(eachReview.array.reply)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="read-more" v-if="reviews.length !== totalComments">
                      <Button
                        type="flat"
                        color="primary"
                        value="Read More"
                        buttonClass="continue-button"
                        @buttonClicked="readMoreFunc(reviews.length + 5)"
                      />
                    </div>
                  </div>
              </div>
            </v-card>
          </div> -->
        </div>
        <div class="rigth-container">
          <v-card class="margin-bottom-26">
            <div class="micro-stay-header">
              <div class="booking-date-container">
                <v-row no-gutters class="date-box">
                   <v-col >
                  <div class="label-header">Check in date</div>
                  </v-col>
                  <v-col order="6">
                  <DatePicker v-model="dateSelected" @onChange="getDate" />
                  </v-col>
                   </v-row>
              </div>
              <div class="select-text">Step 1 of 2: Select Room Type and Duration</div>
            <h3>Available rooms</h3>
            <v-row class="loader" v-if="roomLoadingInProgress" align-content="center" justify="center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
            <div v-if="!roomLoadingInProgress && selectedHotel.rooms
            && selectedHotel.rooms.length > 0">
              <div @click="(room.isAvailable && room.status === 'ACTIVE'
              && searchHotelsError === null) ?
              roomSelected(room) : null"
                v-for="(room, i) in selectedHotel.rooms" :key="i" class="margin-bottom-26">
                 <RoomCard :room="room"
                  :isDisabled="!room.isAvailable || room.status === 'INACTIVE'
                  || searchHotelsError !== null"
                  :isSelected="room.isSelected"
                  :markerStatus="markerStatus"
                  :timeSelected="timeSelected"
                  :dateSelected="dateSelected"
                  :resetValue="resetValue"
                  :currency="getCurrencyData"
                  @childData="cardCheckboxClick"
                  />
              </div>
            </div>
            <div v-if="selectedHotel.rooms?.length ===0 && !roomLoadingInProgress"
            class="no-search-results">
              No rooms
            </div>
            </div>
          </v-card>
          <v-card class="margin-bottom-26">
            <div class="micro-stay-header">
              <div class="select-text">Step 2 of 2: Select Time Slot</div>
            <h3>Available time slots</h3>
            <v-row class="loader" v-if="roomLoadingInProgress" align="center" justify="center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
            <div v-if="!roomLoadingInProgress && selectedHotel.rooms
            && selectedHotel.rooms.length > 0">
            <div class="time-slot-container time-available">
              <div class="no-select-slot" v-if="this.timeSlots.length === 0">
                <div v-if="!hourSelect && timeSlots.length === 0">
              <v-icon class='icon'>mdi-clock-alert</v-icon>
              <div class="no-select-slot-text">Select a duration to get time slots</div>
              </div>
              <div v-else>
              <v-icon class='icon'>mdi-clock-remove-outline</v-icon>
              <div class="no-slot-text">Sorry, No time slots are available for today. Please choose another date.</div>
              </div>
              </div>
              <a :class="slot._id === selectedTimeSlot._id ? 'selected-time-slots time-slots' : 'time-slots'" v-for="slot in this.timeSlots" :key="slot._id" v-else @click="onSlotSelect(slot)">
                <v-icon :class="slot._id === selectedTimeSlot._id ? 'selected-clock-icon clock-icon' :'clock-icon'">mdi-clock-outline</v-icon>
                <div :class="slot._id === selectedTimeSlot._id ? 'time-text selected-time-text':'time-text'">{{slot.checkin}} - {{slot.checkout}}</div>
              </a>
            </div>
            </div>
            </div>
          </v-card>
          <v-card>
            <v-form @submit.prevent="bookRoom()">
            <!-- <div class="micro-stay-header">
              <h3>Book your micro-stay</h3>
              <div v-if="selectedRoom.name" class="active-room">{{selectedRoom.name}}</div>
              <div v-if="!selectedRoom.name" class="select-room">Select a room to continue</div>
            </div> -->
            <!-- <div class="micro-stay-date-container">
              <div class="booking-date-container">
                <v-row no-gutters>
                   <v-col >
                  <div class="label-header">Check in date</div>
                  </v-col>
                  <v-col order="6">
                  <DatePicker v-model="dateSelected" @onChange="getDate" />
                  </v-col>
                   </v-row>
                  <div class="margin-left-8">
                  <div class="label-header">Check in time</div>
                    <TimeSelector :date="dateSelected" v-model="timeSelected" @onChange="getTime" />
                </div>
              </div>
              <div>
                <div class="label-header">Stay duration</div>
                <div class="check-box-container">
                  <div class="check-box" @click="cardCheckboxClick(duration)"
                    v-for="duration in stayDuration" :key="duration">
                    <CardCheckbox :label="duration" :isSelected="duration === selectedDuration" />
                  </div>
                </div>
              </div>
            </div> -->
            <Button
              type="submit"
              width="100%"
              height="65px"
              color="primary"
              value="Continue"
              class="btn-continue"
              :disabled="!selectedTimeSlot._id"
              buttonClass="btn-continue"
            />
            </v-form>
          </v-card>
        </div>
      </v-flex>
      <div v-if="openDialog">
        <v-dialog v-model="openDialog" max-width="370px">
          <Dialog
            :dialogData="searchHotelsError"
            :onCloseDialog="closeDialog"
            mode="snackbar-error"
          />
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import * as moment from 'moment';
import Carousel from '@/common/ViewHotelCarousel.vue';
import RoomCard from '@/common/RoomCard.vue';
import GoogleMap from '@/common/GoogleMap.vue';
import {
  GOOGLE_MARKERS, HTTP_STATUS_CODE, TOASTER_MESSAGE, RATINGS_TYPE,
} from '@/constants';
import DatePicker from '@/common/DatePicker.vue';
import TimeSelector from '@/common/TimeSelector.vue';
// import CardCheckbox from '@/common/CardCheckbox.vue';
import Dialog from '@/common/ErrorDialog.vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import axios from '../axios';

Vue.use(VueCookies);
const { getLoggedInUserDetailService } = require('@/services/user/user.service');

const {
  setFavorite,
  setUnfavorite,
} = require('@/services/hotels/favourites.service');
const {
  getReviewTags,
} = require('@/services/reviews/review.service');
const { getCurrencies } = require('@/services/user/user.service');

export default {
  name: 'ViewHotel',
  props: ['items'],
  data() {
    return {
      markers: GOOGLE_MARKERS,
      selectedDuration: '',
      stayDuration: ['3h', '6h', '12h'],
      dateSelected: this.getCurrentDate(),
      timeSelected: undefined,
      amenities: [],
      selectedRoom: {},
      loadingInProgress: true,
      roomLoadingInProgress: false,
      snackbar: false,
      toasterMessage: '',
      hotelId: '',
      openDialog: false,
      categories: [],
      reviews: [],
      totalComments: 0,
      allReviews: [],
      ratings: 5,
      ratingReviews: null,
      readMore: 0,
      rating: 0,
      ratingInfo: '',
      selectedDate: null,
      selectedTime: null,
      selectedHours: null,
      searchHotelsError: null,
      selectedHotel: {},
      saveSelectedRoom: {},
      saveSelectedRoomId: '',
      markerStatus: '',
      timeSlots: [],
      hourSelect: '',
      selectedTimeSlot: {},
      resetValue: false,
      showFavorite: false,
      isMobile: window.innerWidth <= 768,
      showReadMoreButton: true,
      wordsToShow: 50,
    };
  },
  components: {
    Carousel,
    RoomCard,
    GoogleMap,
    // TimeSelector,
    DatePicker,
    // CardCheckbox,
    Dialog,
  },
  computed: {
    lonlatMarkers() {
      return [
        {
          position: {
            lat: this.selectedHotel?.location?.coordinates[1],
            lng: this.selectedHotel?.location?.coordinates[0],
          },
          selectedMarker: true,
        },
      ];
    },
    ...mapGetters({
      getCurrencyData: 'getCurrencyData',
    }),
  },
  watch: {
    searchHotelsError() {
      if (this.searchHotelsError !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      this.loadingInProgress = false;
    },
  },
  mounted() {
    if (!window.$cookies.get('token')) {
      this.showFavorite = false;
    } else {
      this.showFavorite = true;
    }
    const { query } = this.$route;
    this.getHotel(true);
    this.setHeaderAccess();
    // const [saveRoom] = query.room;
    // this.saveSelectedRoom = saveRoom;
    this.cardCheckboxClick(query.cardDuration);
    // this.roomSelected(saveRoom);
    // this.rating = parseInt(this.selectedHotel.avgReview, 0);
    // const { query } = this.$route;
    // const data = this.$route.params.searchHomeValue;
  },
  created() {
    const { query } = this.$route;
    this.selectedTime =	query.time;
    this.selectedDate =	query.date;
    this.selectedHours =	query.duration;
    this.rating = parseInt(this.selectedHotel.avgReview, 0);
    this.dateSelected = this.selectedDate || this.getCurrentDate();
    this.timeSelected = this.selectedTime;
    this.hotelId = this.$route.params.id;
    // if (this.selectedHours) {
    //   this.selectedDuration = `${this.selectedHours}h`;
    // }
    // this to unset room selected when comming back
    // this.roomSelected();
    // this.amenities = this.selectedHotel.amenities.slice(0, 4);
    this.markerStatus = query.cardDuration;
  },
  methods: {
    ...mapActions(['getHotelDetails', 'resetSearchError', 'setSelectedHours', 'resetGuest', 'resetSelectedUserType', 'setCurrencyData']),
    ...mapMutations(['setSelectedRoom']),
    async setHeaderAccess() {
      const isLoggedIn = this.$cookies.get('isLoggedIn');
      const token = this.$cookies.get('token');
      if ((isLoggedIn === 'false' && !token) || isLoggedIn === null) {
        const getVatgstDetails = await getCurrencies();
        if (!Vue.$cookies.get('localeInfo') || Vue.$cookies.get('localeInfo').countryNameVatgst === '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
          this.setCurrencyData(filterCountry[0]);
        }
        if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
          if (!filterCountry.length) {
            const aUfilterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
            this.setCurrencyData(aUfilterCountry[0]);
          } else {
            this.setCurrencyData(filterCountry[0]);
          }
        }
      } else {
        const userDetails = await getLoggedInUserDetailService();
        this.setCurrencyData(userDetails.data.vatgstDetails[0]);
      }
    },
    renderHTML(htmlData) {
      return `<p>${htmlData}</p>`;
    },
    renderDescriptionHTML(htmlData) {
      const words = htmlData.split(' ');
      const ellipsis = this.wordsToShow < words.length ? '...' : '';
      return `<p>${words.slice(0, this.wordsToShow).join(' ')}${ellipsis}</p>`;
    },
    toggleReadMore() {
      this.showReadMoreButton = false;
      this.wordsToShow = this.wordsToShow === 50 ? Infinity : 50; // Show all words
    },
    // async getReviewCategories() {
    //   try {
    //     const reviewTags = await getReviewTags();
    //     if (reviewTags.status === HTTP_STATUS_CODE.SUCCESS) {
    //       const array = [];
    //       reviewTags.data.forEach(data => array.push(data.name));
    //       this.categories = array;
    //       const reviewArray = [];
    //       const tags = [];
    //       this.selectedHotel.review.forEach((element) => {
    //         element.tags.forEach((eachTag) => {
    //           if (tags.includes(eachTag.key)) {
    //             const index = tags.indexOf(eachTag.key);
    //             if (index > -1) {
    //               reviewArray[index][eachTag.key] = parseInt((reviewArray[index][eachTag.key]
    //               + eachTag.value), 0);
    //             }
    //           } else {
    //             tags.push(eachTag.key);
    //             reviewArray.push({ [eachTag.key]: eachTag.value });
    //           }
    //         });
    //       });
    //       this.categories.forEach((element) => {
    //         const reviewElement = reviewArray.find(x => Object.getOwnPropertyNames(x).some(y => y === element));
    //         if (reviewElement) {
    //           reviewElement[element] = parseInt((reviewElement[element]
    //           / this.selectedHotel?.review?.length), 0);
    //         }
    //       });
    //       this.ratingReviews = reviewArray;
    //     }
    //   } catch (err) {
    //     this.loadingInProgress = false;
    //   }
    // },
    getCurrentDate() {
      // Add 30 minutes to current time and update time to nearest 30 minute interval
      // const currentDate = new Date();
      // const updatedDate = new Date(currentDate.getTime() + 30 * 60000);
      // const remainder = 30 - (moment(updatedDate).minute() % 30);
      // return new Date(moment(updatedDate).add(remainder, 'minutes'));
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const tomorrowFormatted = tomorrow;
      return moment(tomorrowFormatted).format('YYYY-MM-DD');
    },
    async getHotel(showLoading) {
      this.loadingInProgress = showLoading;
      this.roomLoadingInProgress = showLoading;
      this.resetSearchError();
      try {
        const searchData = {
          hotelId: this.hotelId,
          date: moment(this.dateSelected).format('YYYY-MM-DD'),
          time: this.timeSelected === undefined ? null : this.timeSelected,
          duration: this.selectedHours || '3,6,12',
        };
        await this.getHotelDetails(searchData);
        // this to unset room selected when comming back
        // this.getReviews();
        // this.getReviewCategories();
        this.roomSelected();
        this.amenities = this.selectedHotel.amenities.slice(0, 4);
        this.loadingInProgress = false;
        this.roomLoadingInProgress = false;
      } catch (err) {
        this.loadingInProgress = false;
        this.roomLoadingInProgress = false;
      }
    },
    // getReviews() {
    //   const rateType = RATINGS_TYPE.filter(data => data.value === parseInt(this.rating, 0));
    //   if (rateType.length > 0) {
    //     this.ratingInfo = rateType[0].type;
    //   }
    //   const array = [];
    //   this.selectedHotel.review.forEach((element) => {
    //     const name = `${element.userId.firstName} ${element.userId.lastName}`;
    //     const splitName = name.split(' ');
    //     let fName = '';
    //     if (splitName.length > 0) {
    //       [fName] = splitName;
    //     } else {
    //       fName = name;
    //     }
    //     element.updatedAt = moment(element.updatedAt).format('MMM DD YYYY');
    //     array.push({
    //       name: fName,
    //       review: element.comment,
    //       date: moment(element.createdAt).format('MMM DD YYYY'),
    //       array: element,
    //     });
    //   });
    //   this.allReviews = array;
    //   setTimeout(() => {
    //     this.readMoreFunc(5);
    //   }, 1000);
    // },
    readMoreFunc(inc) {
      this.reviews = [];
      this.allReviews.forEach((eachElement, index) => {
        if (eachElement.review.length > 0) {
          if (index < this.readMore + inc) {
            this.reviews.push(eachElement);
          }
          this.totalComments = this.totalComments + 1;
        }
      });
    },
    loadAmenitie() {
      if (this.amenities.length < this.selectedHotel.amenities.length) {
        this.amenities = this.selectedHotel.amenities;
      } else {
        this.amenities = this.selectedHotel.amenities.slice(0, 4);
      }
    },
    loadDescription() {

    },
    getIconAmenities(amenitie) {
      switch (amenitie) {
        case 'Restaurant': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/restaurant.png';
        case 'Bar': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/bar.png';
        case 'Room service': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/meal.png';
        case 'Kitchen': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/kitchen.png';
        case 'Fitness centre': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/fitness+centre.png';
        case 'Free Wifi': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/wifi.png';
        case 'Elevator': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/elevator.png';
        case 'Business centre': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/business+centre.png';
        case 'Sauna': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/sauna.png';
        case 'Salon': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/salon.png';
        case 'Laundry': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/laundry.png';
        case 'Accessible rooms': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/accessible+rooms.png';
        case 'Rooftop': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/rooftop.png';
        case 'Parking': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/parking.png';
        case 'Airport Shuttle': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/airport+shuttle.png';
        case '24 hour reception': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/reception.png';
        case 'Air conditioning': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/air+conditioner.png';
        case 'Money exchange': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/money+exchange.png';
        case 'Smoking zone': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/smoking.png';
        case 'Toiletries': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/toiletries.png';
        case 'Pool': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/pool.png';
        case 'Tea & Coffee': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/Tea_%26_Coffee.png';
        case 'Housekeeping': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/housekeeping.png';
        case 'Ensuite': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/Ensuite.png';
        case 'Mini Bar': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/minibar.png';
        case 'Microwave': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/microwave.png';
        case 'BBQ': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/BBQ.png';
        case 'Kitchenette': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/kitchenette.png';
        case 'Television': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/television.png';
        case 'Balcony': return 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/balcony.png';
        default: return '';
      }
    },
    getDate(value) {
      if (value) {
        this.dateSelected = moment(value).format('YYYY-MM-DD');
        // this.getHotel(true);
        this.selectedDate = value;
        this.timeSlots = [];
        this.selectedRoom = {};
        this.setSelectedHours('');
        this.hourSelect = '';
        this.getHotel(false);
        if (moment().format('YYYYMMDD') !== this.dateSelected) {
          this.resetValue = true;
        } else {
          this.resetValue = false;
        }
      } else {
        this.dateSelected = '';
      }
    },
    getTime(time) {
      this.timeSelected = moment(time, 'h:mm a').format('HH:mm');
      this.getHotel(false);
    },
    cardCheckboxClick(duration) {
      switch (duration) {
        case '3h':
          this.setSelectedHours('3');
          this.hourSelect = '3';
          break;
        case '6h':
          this.setSelectedHours('6');
          this.hourSelect = '6';
          break;
        case '12h':
          this.setSelectedHours('12');
          this.hourSelect = '12';
          break;
        default:
          this.setSelectedHours('');
          break;
      }
      this.selectedDuration = duration;
    },

    handelTimeSlots(slots) {
      const currentTime = moment().format('HH:mm');
      const filteredData = slots.filter(
        obj => currentTime <= obj.checkin && currentTime <= obj.checkout,
      );
      if (
        moment().format('YYYYMMDD') === moment(this.selectedDate).format('YYYYMMDD')
      ) {
        this.timeSlots = filteredData;
      } else {
        this.timeSlots = slots;
      }
    },
    onSlotSelect(slot) {
      this.selectedTimeSlot = slot;
    },
    roomSelected(selectedRoom) {
      this.saveSelectedRoom = selectedRoom ? selectedRoom : this.saveSelectedRoom;
      // if (this.saveSelectedRoomId === '') {
      //   this.saveSelectedRoomId = this.saveSelectedRoom._id;
      // }
      let count = 0;
      this.selectedHotel.rooms = this.selectedHotel.rooms.map((room) => {
        // eslint-disable-next-line no-underscore-dangle
        if (this.saveSelectedRoom && this.saveSelectedRoom._id === room._id) {
          room.isSelected = true;
          if (this.hourSelect === '3') {
            this.handelTimeSlots(this.saveSelectedRoom.availableSlotsThreeHours);
          }
          if (this.hourSelect === '6') {
            this.handelTimeSlots(this.saveSelectedRoom.availableSlotsSixHours);
          }
          if (this.hourSelect === '12') {
            this.handelTimeSlots(this.saveSelectedRoom.availableSlotsTweleveHours);
          }
          // if (this.saveSelectedRoomId !== this.saveSelectedRoom._id) {
          //   this.selectedDuration = '';
          //   this.markerStatus = '';
          //   this.saveSelectedRoomId = this.saveSelectedRoom._id;
          // }
        } else {
          room.isSelected = false;
          // this.selectedDuration = '';
        }
        if (room.isSelected) {
          count += 1;
          this.selectedRoom = room;
        }
        return room;
      });
      if (count === 0) {
        this.selectedRoom = {};
      }
    },
    bookRoom() {
      const roomDetails = {
        // eslint-disable-next-line no-underscore-dangle
        _id: this.selectedHotel._id,
        name: this.selectedHotel.name,
        date: moment(this.dateSelected).format('YYYY-MM-DD'),
        time: this.timeSelected,
        duration: this.selectedDuration,
        selectedRoom: this.selectedRoom,
        blocked: this.selectedHotel.blocked,
        timeSlot: this.selectedTimeSlot,
      };
      this.setSelectedRoom(roomDetails);
      localStorage.setItem('bookingroomDetails', JSON.stringify(roomDetails));
      this.resetGuest();
      this.resetSelectedUserType();
      localStorage.setItem('bookingStatus', 'false');
      this.$router.push({ path: '/book-hotel' });
    },
    async toogleFavourites(isFavorited) {
      if (isFavorited) {
        try {
          // eslint-disable-next-line no-underscore-dangle
          const setUnfavoriteResponse = await setUnfavorite(this.selectedHotel._id);
          if (setUnfavoriteResponse.status === HTTP_STATUS_CODE.SUCCESS) {
            this.selectedHotel.isFavorited = !this.selectedHotel?.isFavorited;
          } else {
            this.showErrorMessage();
          }
        } catch (err) {
          this.showErrorMessage(err);
        }
      } else {
        try {
          // eslint-disable-next-line no-underscore-dangle
          const setFavoriteResponse = await setFavorite(this.selectedHotel._id);
          if (setFavoriteResponse.status === HTTP_STATUS_CODE.SUCCESS) {
            this.selectedHotel.isFavorited = !this.selectedHotel?.isFavorited;
          } else {
            this.showErrorMessage();
          }
        } catch (err) {
          this.showErrorMessage(err);
        }
      }
    },
    showErrorMessage() {
      this.snackbar = true;
      this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      this.loadingInProgress = false;
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
    },
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
    async getHotelDetails(payload) {
      try {
        const { data } = await axios.get(`mobile/hotel/${payload.hotelId}/search?date=${payload.date}&time=${payload.time}&duration=${payload.duration}`);
        [this.selectedHotel] = data;
        const { query } = this.$route;
        const [selectedRoom] = this.selectedHotel.rooms.filter(item => item._id === query.room);
        this.saveSelectedRoom = selectedRoom;
        this.selectedHotel.rooms = this.selectedHotel.rooms.map((room) => {
        // eslint-disable-next-line no-underscore-dangle
          if (this.saveSelectedRoom && this.saveSelectedRoom._id === room._id) {
            room.isSelected = true;
            this.selectedRoom = room;
            if (this.hourSelect === '3') {
              this.handelTimeSlots(this.saveSelectedRoom.availableSlotsThreeHours);
            }
            if (this.hourSelect === '6') {
              this.handelTimeSlots(this.saveSelectedRoom.availableSlotsSixHours);
            }
            if (this.hourSelect === '12') {
              this.handelTimeSlots(this.saveSelectedRoom.availableSlotsTweleveHours);
            }
          } else {
            room.isSelected = false;
          }
          return room;
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          this.searchHotelsError = err.response.data.error;
        } else {
          this.searchHotelsError = TOASTER_MESSAGE.TROUBLE;
        }
      }
    },
    getReviewElement(element) {
      const nullObject = {};
      nullObject[element] = 0;
      return this.ratingReviews.find(x => Object.getOwnPropertyNames(x).some(y => y === element)) || nullObject;
    },
  },
};
</script>

<style scoped lang="scss">
  .page-back {
    display: flex;
    .page-back-icon {
   font-size: 30px;
   margin-right: 10px;
  }
    .page-back-text {
      font-size: 23px;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 700;
     }
  }

.view-hotel-container {
  .view-hotel-heading-container {
    padding: 46px 0;
    width: 100%;
    padding-left: 5px;
  }
  .view-hotel-heading-outer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left-container {
    width: 60%;
  }
  .rigth-container {
    width: 40%;
    margin: 0px 0 0 30px;
    .vue__time-picker input.display-time{
      border: 2px solid #dcdee6;
      height: 2.8em;
    }
    .vue__time-picker .controls .char{
      font-size: 30px;
      color: #b7b8bb;
    }
  }
  .margin-bottom-26 {
    margin-bottom: 26px;
    margin-top: 10px;
  }

  .box-sixe {
    width: 95%;
    margin-left: 10px;
  }
  .padding-bottom-26 {
    padding-bottom: 26px;
  }
  .card-title {
    padding: 10px 16px;
    border-bottom: 1px solid #c4c5cc;
    font-weight: bold;
  }
  .map-container {
    height: 320px;
  }
  .amenities-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .amenities-list {
    padding: 12px;
    margin: 0 12px;
    min-width: 357px;
    display: flex;
    text-align: justify;
  }
  .amenitie-icon {
    margin-right: 10px;
  }
  .outline-btn {
    background: #fef1eb !important;
    border: 2px solid #FF4500 !important;
    color: #FF4500;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    padding: 10px;
    margin-right: 4px;
    cursor: pointer;
    text-align: center;
    margin: 16px 16px 0 16px;
  }
  .contact-container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .contact-sub-container {
      width: 360px;
      margin: 0 9px 9px 0;
    }
  }
  .stars {
    display: flex;
    align-items: center;
    margin-left: 100px;
    ::v-deep .v-rating .v-icon {
      padding: unset !important;
    }
  }
  .category-rating{
    background-color: #FF4500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 50px;
    color: #FFF;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
  }
  .reviews-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 10px;
    margin-left: 10px;
  }
  .ratings-box{
    padding: 15px;
  }
  .review-box{
    /* max-height: 500px;
    overflow-y: scroll; */
    padding: 8px;
  }
  .reviews{
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 5px;
    margin-top: 8px;
    .user-tile{
      padding: 5px;
      background-color: #FF4500;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      color: #fff;
      text-align: center;
    }
    .user-name{
      margin-left: 8px;
      margin-top: 12px;
    }
    .review-date{
      font-size: 12px;
    }
    .review-details{
      padding: 8px;
    }
    .review-response{
      border-left: 1px solid #ccc;
      margin-left: 14px;
      padding: 8px;
    }
    .round-image{
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
  .read-more{
    color: red;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }
  .view-hotel-heading {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 5px;
  }
  .location-icon {
    font-size: 18px;
    margin-right: 8px;
  }
  .select-room, .active-room {
    background: #F7F7FC;
    border: 1px dashed #C4C5CC;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 17px;
    margin-top: 19px;
  }
  .active-room {
    border: 1px solid #C4C5CC;
  }
  .micro-stay-header {
    padding: 24px;
    border-bottom: 1px solid #c4c5cc;
  }
  // .micro-stay-date-container {
  //   padding: 24px;
  // }
  .label-header {
    color: #080914;
    font-size: 18px;
    font-weight: 700;
    margin-top: 5%;
  }
  .booking-date-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #C4C5CC;
    margin: 25px  0px;
    padding: 15px 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  }
  .date-box {
    margin: 5px;
  }
  .margin-left-8 {
    margin-left: 8px;
  }
  .check-box-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .check-box {
      width: 30%;
    }
  }
  .no-image-card {
    height: 400px;
    background-color: #c4c5cc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .favourite-icon {
    font-size: 35px;
    // margin-right: 10px;
    margin-left: 22%;
  }
  .favourite {
    color: #FF4500;
  }
  .no-search-results {
    font-size: 18px;
    line-height: 24px;
    padding: 30px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    min-height: 145px;
  }
  .hotel-details{
    padding: 6px;
  }

  .select-text {
    font-size: 18px;
    color: #FF4500;
    margin-bottom: 20px;
    font-weight: 700;
    background: #fef1eb;
    padding: 5px 10px;
  }

  .time-slot-container {
    background: #fef1eb;
    width: 100%;
    height: 250px;
    margin-top: 10px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .time-available {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    overflow-y: auto;
  }

  .time-slots{
    display: flex;
    background: #ffffff;
    padding: 5px 10px;
    margin: 5px 10px;
    border-radius: 20px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  }

  .selected-time-slots{
    background:  #FF4500;
  }
  .time-text {
    font-weight: 700;
    padding-left: 5px;
    color:  rgba(0, 0, 0, 0.7);
  }
  .selected-time-text {
    color:  #ffffff;
  }
  .clock-icon {
    font-size: 25px;
    color: #FF4500;
  }
  .selected-clock-icon {
    color: #ffffff;
  }
  .icon {
  font-size: 50px;
  }
  .no-select-slot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  }
  .no-select-slot-text{
    font-size: 18px;
    color: #b7b8bb;
    margin-top: 20px;
  }

  .no-slot-text {
    font-size: 18px;
    color: #FF4500;
    margin-top: 20px;
  }

  .btn-continue {
    font-size: 20px;
    color: #000;
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .left-right-outer-container {
    flex-direction: column;
    .left-container {
      width: 100% !important;
    }
    .rigth-container {
      width: 100% !important;
      margin: 0px 0 30px 0 !important;
      .vue__time-picker input.display-time{
        border: 2px solid #dcdee6 !important;
        height: 2.8em !important;
      }
      .vue__time-picker .controls .char{
        font-size: 30px !important;
        color: #b7b8bb !important;
      }
    }
    .contact-sub-container {
      width: 100% !important;
    }
    .amenities-list {
      margin: 0px !important;
      min-width: 100% !important;
    }
    ::v-deep .room-card-content-container {
      flex-direction: column !important;
    }
  }
  .view-hotel-heading-container {
    .view-hotel-heading h1 {
      font-size: 30px !important;
    }
    .stars {
      margin-left: 10px !important;
    }
  }
  .view-hotel-container .time-slots {
    margin: 5px;
    padding: 5px 7px;
  }
  .view-hotel-container .time-text {
    font-size: 16px;
}
}
</style>
