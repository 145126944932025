<template>
  <div>
    <div
      class="room-card"
      :class="{
        'room-card-selected': this.markers && isSelected,
        'room-card-disabled': isDisabled,
        pointer: !isDisabled,
      }"
    >
      <div class="room-card-header">
        <!-- <div class="card-checkbox" :class="{'card-checkbox-selected': isSelected}"></div> -->
        <div class="room-card-header-text">{{ room.name }}</div>
      </div>
      <div class="room-card-content-container room-card-content">
        <div class="room-card-details">
          <div class="room-card-content-text">
            <v-icon class="icon">mdi-hotel</v-icon>
            <div class="room-card-content-details">
              {{ room.kingBeds > 0 ? `${room.kingBeds} King bed` : null }}
              {{
                room.kingBeds > 0 &&
                (room.queenBeds > 0 || room.singleBeds > 0 || room.doubleBeds > 0)
                  ? `, `
                  : null
              }}
              {{ room.queenBeds > 0 ? `${room.queenBeds}  Queen beds` : null }}
              {{ room.queenBeds > 0 && (room.singleBeds > 0 || room.doubleBeds > 0) ? `, ` : null }}
              {{ room.singleBeds > 0 ? `${room.singleBeds} Single beds` : null }}
              {{ room.queenBeds > 0 && room.doubleBeds > 0 ? `, ` : null }}
              {{ room.doubleBeds > 0 ? `${room.doubleBeds} Double beds` : null }}
            </div>
          </div>
          <div class="room-card-content-text">
            <v-icon class="icon">mdi-key</v-icon>
            <div class="room-card-content-details">Access hotel amenities</div>
          </div>
        </div>
        <div class="room-card-content hours-card color-card">
          <div
            class="room-card-rates"
            v-bind:style="[
              !room.isAvailableThree
                ? { background: '#F5F5F5' }
                : this.isSelected && this.markers === '3h'
                ? { border: '2px solid #FF4500' }
                : { border: '2px solid #ddd' },
            ]"
            @click="room.isAvailableThree && sendTimeData('3h')"
          >
            <div class="card-style">
              <div
                class="time-checkbox"
                :class="{
                  'time-checkbox-selected': this.isSelected ? this.markers === '3h' : false,
                }"
                @click="room.isAvailableThree && sendTimeData('3h')"
              ></div>
              <div
                class="room-card-hour-value"
                v-bind:style="[
                  room.isAvailableThree
                    ? this.isSelected && this.markers === '3h'
                      ? { 'font-weight': '700', color: '#FF4500' }
                      : { 'font-weight': '700', color: '#080914' }
                    : { 'font-weight': '500' },
                ]"
              >
                {{ room.isAvailableThree ? `${currency?.shortName}${Math.ceil(room.threeHourRate * currency.exchangeRate)}` : '--' }}
              </div>
            </div>
            <div
              class="room-card-hour-header"
              v-bind:style="[
                room.isAvailableThree
                  ? this.isSelected && this.markers === '3h'
                    ? { 'font-weight': '700', color: '#FF4500' }
                    : { 'font-weight': '700', color: '#080914' }
                  : { 'font-weight': '500' },
              ]"
            >
              3 hours
            </div>
          </div>
          <div
            class="room-card-rates"
            v-bind:style="[
              !room.isAvailableSix
                ? { background: '#F5F5F5' }
                : this.isSelected && this.markers === '6h'
                ? { border: '2px solid #FF4500' }
                : { border: '2px solid #ddd' },
            ]"
            @click="room.isAvailableSix && sendTimeData('6h')"
          >
            <div class="card-style">
              <div
                class="time-checkbox"
                :class="{
                  'time-checkbox-selected': this.isSelected ? this.markers === '6h' : false,
                }"
                @click="room.isAvailableSix && sendTimeData('6h')"
              ></div>
              <div
                class="room-card-hour-value"
                v-bind:style="[
                  room.isAvailableSix
                    ? this.isSelected && this.markers === '6h'
                      ? { 'font-weight': '700', color: '#FF4500' }
                      : { 'font-weight': '700', color: ' #080914' }
                    : { 'font-weight': '500' },
                ]"
              >
                {{ room.isAvailableSix ? `${currency?.shortName}${Math.ceil(room.sixHourRate * currency.exchangeRate)}` : '--' }}
              </div>
            </div>
            <div
              class="room-card-hour-header"
              v-bind:style="[
                room.isAvailableSix
                  ? this.isSelected && this.markers === '6h'
                    ? { 'font-weight': '700', color: '#FF4500' }
                    : { 'font-weight': '700', color: '#080914' }
                  : { 'font-weight': '500' },
              ]"
            >
              6 hours
            </div>
          </div>
          <div
            class="room-card-rates"
            v-bind:style="[
              !room.isAvailableTwelve
                ? { background: '#F5F5F5' }
                : this.isSelected && this.markers === '12h'
                ? { border: '2px solid #FF4500' }
                : { border: '2px solid #ddd' },
            ]"
            @click="room.isAvailableTwelve && sendTimeData('12h')"
          >
            <div class="card-style">
              <div
                class="time-checkbox"
                :class="{
                  'time-checkbox-selected': this.isSelected ? this.markers === '12h' : false,
                }"
                @click="room.isAvailableTwelve && sendTimeData('12h')"
              ></div>
              <div
                class="room-card-hour-value"
                v-bind:style="[
                  room.isAvailableTwelve
                    ? this.isSelected && this.markers === '12h'
                      ? { 'font-weight': '700', color: '#FF4500' }
                      : { 'font-weight': '700', color: '#080914' }
                    : { 'font-weight': '500' },
                ]"
              >
                {{ room.isAvailableTwelve ? `${currency?.shortName}${Math.ceil(room.twelveHourRate * currency.exchangeRate)}` : '--' }}
              </div>
            </div>
            <div
              class="room-card-hour-header"
              v-bind:style="[
                room.isAvailableTwelve
                  ? this.isSelected && this.markers === '12h'
                    ? { 'font-weight': '700', color: '#FF4500' }
                    : { 'font-weight': '700', color: '#080914' }
                  : { 'font-weight': '500' },
              ]"
            >
              12 hours
            </div>
          </div>
        </div>
      </div>
      <v-flex v-if="showFreeCancelDateTime" class="room-free-cancellation">
        <!-- Free cancellation until {{ getFreeCancelDateTime(dateSelected, timeSelected).time}} on {{ getFreeCancelDateTime(dateSelected, timeSelected).date}} -->
        <v-icon class="free-cancel-icon">mdi-check-circle-outline</v-icon> Free cancellation until
        24 hours before checkin.
      </v-flex>
      <div v-if="isDisabled" class="error-text">
        <v-icon color="black" size="18">mdi-alert-circle</v-icon>
        <span>This room is unavailable</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'RoomCard',
  data() {
    return {
      markers: '',
    };
  },
  props: [
    'room',
    'isSelected',
    'isDisabled',
    'dateSelected',
    'timeSelected',
    'markerStatus',
    'resetValue',
    'currency',
  ],
  mounted() {
    this.sendTimeData(this.markerStatus);
    // this.markers = this.markerStatus;
  },
  computed: {
    showFreeCancelDateTime() {
      return moment(`${this.dateSelected} ${this.timeSelected}`, 'YYYY-MM-DD HH:mm')
        .subtract(24, 'hours')
        .subtract(1, 'minutes')
        .isAfter(moment());
    },
  },
  methods: {
    getFreeCancelDateTime(date, time) {
      const datetime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
        .subtract(24, 'hours')
        .subtract(1, 'minutes');
      return {
        date: datetime.format('DD MMM YYYY'),
        time: datetime.format('hh:mm A'),
      };
    },

    sendTimeData(option) {
      this.markers = option;
      this.$emit('childData', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.room-free-cancellation {
  font-size: 14px;
  color: #52535c;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ff4500;
  .free-cancel-icon {
    font-size: 16px;
    color: #228b22;
  }
}
.room-card-selected {
  border: 2px solid #ff4500;
  // background: #fef1eb !important;
}
.room-card-disabled {
  border: 2px solid #d2d2d2;
  background: #ececec !important;
}
.error-text {
  color: $error-color;
  font-size: 15px;
  background: rgba(230, 58, 60, 0.1);
  border-radius: 4px;
  padding: 5px 10px;
  margin-top: 5px;
  span {
    margin-left: 10px;
    position: relative;
    top: 1.5px;
  }
}
.room-card {
  padding: 12px;
  margin: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 4px;
  background: white;
  box-shadow: -1px 5px 10px #c7c5c5;

  .room-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    line-height: unset;
  }
  .room-card-hour-value {
    font-size: 20px;
  }
  .room-card-content-container {
    margin-top: 10px;
    // display: flex;
    // justify-content: space-between;
  }
  .color-card {
    background: #fef1eb;
    border-radius: 10px;
  }
  .card-style {
    justify-content: space-between;
    display: flex;
  }
  .room-card-content-details {
    font-size: 16px;
    color: #52535c;
  }
  .room-card-details {
    display: flex;
    flex-direction: column;
  }
  .room-card-content {
    // display: flex;
    // flex-direction: row;
  }
  .room-card-hour-header {
    color: #080914;
  }
  .hours-card {
    padding: 10px;
  }

  .room-card-header-text {
    font-size: 18px;
    font-weight: bold;
    color: #ff4500;
  }
  .room-card-content-text {
    font-family: Abel;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #080914;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .icon {
    width: 15px;
    height: 15px;
    font-size: 20px;
    margin-right: 10px;
  }
  .radio-group {
    margin: unset;
  }
  .v-input--selection-controls {
    padding: 0px !important;
  }
  .v-radio {
    margin-right: 0px !important;
  }
  .room-card-rates {
    //  width: 55px;
    display: flex;
    flex-direction: row;
    //margin-right: 15px;
    border-radius: 10px;
    border: 2px solid #ddd;
    align-items: center;
    text-align: center;
    padding: 5px 10px;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #ffffff;
  }
  .card-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #dcdee6;
    margin-right: 10px;
    position: absolute;
    left: 7px;
  }
  .card-checkbox-selected {
    border: 6px solid #ff4500;
  }
  .time-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #dcdee6;
    margin-top: 5px;
    margin-right: 10px;
    // margin-left: 5px;
  }
  .time-checkbox-selected {
    border: 6px solid #ff4500;
  }
}
</style>
